import React, { useEffect } from "react";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import { Link, useNavigate } from "react-router-dom";
import Container from "../components/Container";
import CustomInput from "../components/CustomInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../features/user/userSlice";

let signUpSchema = yup.object({
  firstname: yup.string().required("First Name is Required"),
  lastname: yup.string().required("Last Name is Required"),
  email: yup
    .string()
    .required("Email is Required")
    .email("Email Should be valid"),
  mobile: yup.number().required().positive().integer("Mobile No is Required"),
  password: yup.string().required("Password is Required"),
});

const Signup = () => {
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      password: "",
    },
    validationSchema: signUpSchema,
    onSubmit: (values) => {
      dispatch(registerUser(values));
    },
  });

  return (
    <>
      <Meta title={"Sign Up"} />
      <BreadCrumb title="Sign Up" />
      <Container class1="login-wrapper py-5 home-wrapper-2">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div
              className="auth-card"
              style={{
                backgroundColor: "white",
                padding: "40px",
                borderRadius: "12px",
                boxShadow: "0 10px 40px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h3 className="text-center mb-4" style={{ color: "#212122", fontWeight: "600", fontSize: "24px"}}>
                Create an Account
              </h3>
              <form
                action=""
                className="d-flex flex-column gap-2"
                onSubmit={formik.handleSubmit}
              >
                <CustomInput
                  type="text"
                  name="firstname"
                  placeholder="First Name"
                  value={formik.values.firstname}
                  onChange={formik.handleChange("firstname")}
                  onBlur={formik.handleBlur("firstname")}
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "2px solid #4c00b0",
                    color: "#4c00b0",
                    borderRadius: "8px",
                    padding: "14px 20px",
                    fontSize: "16px",
                    transition: "border-color 0.3s ease",
                    marginBottom: "12px",
                  }}
                />
                {formik.touched.firstname && formik.errors.firstname && (
                  <div className="error" style={{ color: "#FF4081" }}>
                    {formik.errors.firstname}
                  </div>
                )}

                <CustomInput
                  type="text"
                  name="lastname"
                  placeholder="Last Name"
                  value={formik.values.lastname}
                  onChange={formik.handleChange("lastname")}
                  onBlur={formik.handleBlur("lastname")}
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "2px solid #4c00b0",
                    color: "#4c00b0",
                    borderRadius: "8px",
                    padding: "14px 20px",
                    fontSize: "16px",
                    transition: "border-color 0.3s ease",
                    marginBottom: "12px",
                  }}
                />
                {formik.touched.lastname && formik.errors.lastname && (
                  <div className="error" style={{ color: "#FF4081" }}>
                    {formik.errors.lastname}
                  </div>
                )}

                <CustomInput
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange("email")}
                  onBlur={formik.handleBlur("email")}
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "2px solid #4c00b0",
                    color: "#4c00b0",
                    borderRadius: "8px",
                    padding: "14px 20px",
                    fontSize: "16px",
                    transition: "border-color 0.3s ease",
                    marginBottom: "12px",
                  }}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="error" style={{ color: "#FF4081" }}>
                    {formik.errors.email}
                  </div>
                )}

                <CustomInput
                  type="tel"
                  name="mobile"
                  placeholder="Mobile Number"
                  value={formik.values.mobile}
                  onChange={formik.handleChange("mobile")}
                  onBlur={formik.handleBlur("mobile")}
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "2px solid #4c00b0",
                    color: "#4c00b0",
                    borderRadius: "8px",
                    padding: "14px 20px",
                    fontSize: "16px",
                    transition: "border-color 0.3s ease",
                    marginBottom: "12px",
                  }}
                />
                {formik.touched.mobile && formik.errors.mobile && (
                  <div className="error" style={{ color: "#FF4081" }}>
                    {formik.errors.mobile}
                  </div>
                )}

                <CustomInput
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange("password")}
                  onBlur={formik.handleBlur("password")}
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "2px solid #4c00b0",
                    color: "#4c00b0",
                    borderRadius: "8px",
                    padding: "14px 20px",
                    fontSize: "16px",
                    transition: "border-color 0.3s ease",
                    marginBottom: "20px",
                  }}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="error" style={{ color: "#FF4081" }}>
                    {formik.errors.password}
                  </div>
                )}

                <div className="mt-4 d-flex justify-content-center">
                  <button
                    type="submit"
                    className="button"
                    style={{
                      backgroundColor: "#212122",
                      color: "#fff",
                      padding: "14px 30px",
                      fontSize: "16px",
                      borderRadius: "8px",
                      border: "none",
                      cursor: "pointer",
                      width: "100%",
                      transition: "background-color 0.3s ease, transform 0.2s ease",
                    }}
                    onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")}
                    onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
                  >
                    Register
                  </button>
                </div>

                <div className="mt-3 text-center">
                  <span style={{ color: "#212122", fontSize: "14px" }}>
                    Already have an account?{" "}
                    <Link
                      to="/login"
                      style={{
                        color: "#FF4081",
                        fontSize: "14px",
                        fontWeight: "500",
                        textDecoration: "none",
                      }}
                    >
                      Log In
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Signup;
