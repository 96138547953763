import React from "react";
import { Link } from "react-router-dom";
import { BsLinkedin, BsGithub, BsYoutube, BsInstagram } from "react-icons/bs";
import newsletter from "../images/newsletter.png";
import "../components/Footer.css";
const Footer = () => {
  return (
    <>
      <footer className="py-5" style={{ backgroundColor: "#212122" }}>
        <div className="container-xxl">
          <div className="row justify-content-between">
            {/* Contact Us Section */}
            <div className="col-12 col-md-4 mb-4 mb-md-0">
              <h4 className="mb-4" style={{ color: "#bfff00" }}>Contact Us</h4>
              <div>
                <address className="text-white fs-6">
                  <br />
                  Hyderabad, Telangana <br />
                  PinCode: 500383
                </address>
                <a
                  href="tel:+91 8000000000"
                  className="mt-3 d-block mb-1 text-white text-decoration-none"
                >
                  +91 8000000000
                </a>
                <a
                  href="mailto:hemantht1234@gmail.com"
                  className="mt-2 d-block mb-0 text-white text-decoration-none"
                >
                  hemanth@gmail.com
                </a>
                <div className="social_icons d-flex align-items-center gap-30 mt-4">
                  <a
                    className="text-white text-decoration-none hover-scale"
                    href="https://www.linkedin.com/in/hemanth"
                  >
                    <BsLinkedin className="fs-4" />
                  </a>
                  <a
                    className="text-white text-decoration-none hover-scale"
                    href="https://www.instagram.com/hemanth"
                  >
                    <BsInstagram className="fs-4" />
                  </a>
                  <a
                    className="text-white text-decoration-none hover-scale"
                    href="https://github.com/hemanth"
                  >
                    <BsGithub className="fs-4" />
                  </a>
                  <a
                    className="text-white text-decoration-none hover-scale"
                    href="https://www.youtube.com/channel/UC-hemanth"
                  >
                    <BsYoutube className="fs-4" />
                  </a>
                </div>
              </div>
            </div>

            {/* Information Section */}
            <div className="col-12 col-md-3 mb-4 mb-md-0">
              <h4 className="mb-4" style={{ color: "#bfff00" }}>Information</h4>
              <div className="footer-link d-flex flex-column">
                <Link
                  to="/privacy-policy"
                  className="text-white py-2 mb-1 text-decoration-none hover-underline"
                >
                  Privacy Policy
                </Link>
                <Link
                  to="/refund-policy"
                  className="text-white py-2 mb-1 text-decoration-none hover-underline"
                >
                  Refund Policy
                </Link>
                <Link
                  to="/shipping-policy"
                  className="text-white py-2 mb-1 text-decoration-none hover-underline"
                >
                  Shipping Policy
                </Link>
                <Link
                  to="/term-conditions"
                  className="text-white py-2 mb-1 text-decoration-none hover-underline"
                >
                  Terms & Conditions
                </Link>
                <Link
                  to="/blogs"
                  className="text-white py-2 mb-1 text-decoration-none hover-underline"
                >
                  Blogs
                </Link>
              </div>
            </div>

            {/* Account Section */}
            <div className="col-12 col-md-3 mb-4 mb-md-0">
              <h4 className="mb-4" style={{ color: "#bfff00" }}>Account</h4>
              <div className="footer-link d-flex flex-column">
                <Link
                  to="/about-us"
                  className="text-white py-2 mb-1 text-decoration-none hover-underline"
                >
                  About Us
                </Link>
                <Link
                  to="/faq"
                  className="text-white py-2 mb-1 text-decoration-none hover-underline"
                >
                  Faq
                </Link>
                <Link
                  to="/contact"
                  className="text-white py-2 mb-1 text-decoration-none hover-underline"
                >
                  Contact
                </Link>
              </div>
            </div>

            {/* Quick Links Section */}
            <div className="col-12 col-md-2">
              <h4 className="mb-4" style={{ color: "#bfff00" }}>Quick Links</h4>
              <div className="footer-link d-flex flex-column">
                <Link
                  to="/laptops"
                  className="text-white py-2 mb-1 text-decoration-none hover-underline"
                >
                  Laptops
                </Link>
                <Link
                  to="/headphones"
                  className="text-white py-2 mb-1 text-decoration-none hover-underline"
                >
                  Headphones
                </Link>
                <Link
                  to="/tablets"
                  className="text-white py-2 mb-1 text-decoration-none hover-underline"
                >
                  Tablets
                </Link>
                <Link
                  to="/watch"
                  className="text-white py-2 mb-1 text-decoration-none hover-underline"
                >
                  Watch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* Bottom Footer */}
      <footer className="py-3" style={{ backgroundColor: "grey" }}>
        <div className="container-xxl">
          <div className="row">
            <div className="col-12">
              <p className="text-center mb-0" style={{ color: "#bfff00" }}>
                &copy; {new Date().getFullYear()}; All Rights Reserved | Developed by Hemanth
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;