import React, { useEffect, useState } from "react";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import watch from "../images/watch.jpg";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import Container from "../components/Container";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteCartProduct,
  getUserCart,
  updateCartProduct,
} from "../features/user/userSlice";

const Cart = () => {
  const getTokenFromLocalStorage = localStorage.getItem("customer")
  ? JSON.parse(localStorage.getItem("customer"))
  : null;
  
  const config2 = {
    headers: {
      Authorization: `Bearer ${
        getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ""
      }`,
      Accept: "application/json",
    },
  };
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [productupdateDetail, setProductupdateDetail] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const userCartState = useSelector((state) => state.auth.cartProducts);

  useEffect(() => {
    dispatch(getUserCart(config2));
  }, [dispatch]); // Add `dispatch` as dependency
  
  useEffect(() => {
    if (productupdateDetail !== null) {
      dispatch(
        updateCartProduct({
          cartItemId: productupdateDetail?.cartItemId,
          quantity: productupdateDetail?.quantity,
        })
      );
      setTimeout(() => {
        dispatch(getUserCart(config2));
      }, 200);
    }
  }, [productupdateDetail]);

  const deleteACartProduct = (id) => {
    dispatch(deleteCartProduct({ id: id, config2: config2 }));
    setTimeout(() => {
      dispatch(getUserCart(config2));
    }, 200);
  };

  useEffect(() => {
    let sum = 0;
    for (let index = 0; index < userCartState?.length; index++) {
      sum =
        sum +
        Number(userCartState[index].quantity) * userCartState[index].price;
      setTotalAmount(sum);
    }
  }, [userCartState]);

  return (
    <>
      <Meta title={"Cart"} />
      <BreadCrumb title="Cart" />
      <Container class1="cart-wrapper home-wrapper-2 py-5">
        <div className="row">
          <div className="col-12">
            <div className="cart-header py-3 d-flex justify-content-between align-items-center">
              <h4 className="cart-col-1">Product</h4>
              <h4 className="cart-col-2">Price</h4>
              <h4 className="cart-col-3">Quantity</h4>
              <h4 className="cart-col-4">Total</h4>
            </div>
            {userCartState &&
  userCartState.map((item, index) => {
    if (!item?.productId) return null; // Skip if productId is null

    return (
      <div
        key={index}
        className="cart-data py-3 mb-2 d-flex justify-content-between align-items-center"
      >
        <div className="cart-col-1 gap-15 d-flex align-items-center">
          <div className="w-25">
            {item?.productId?.images?.length > 0 ? (
              <img
                src={item?.productId?.images[0]?.url}
                className="img-fluid"
                alt="product image"
              />
            ) : (
              <p>No Image</p>
            )}
          </div>
          <div className="w-75">
            <p>{item?.productId?.title || "No Title Available"}</p>
            <p className="d-flex gap-3">
              Color:
              <ul className="colors ps-0">
                <li
                  style={{
                    backgroundColor: item?.color?.title || "transparent",
                  }}
                ></li>
              </ul>
            </p>
          </div>
        </div>
        <div className="cart-col-2">
          <h5 className="price">Rs. {item?.price || 0}</h5>
        </div>
        <div className="cart-col-3 d-flex align-items-center gap-15">
          <div>
            <input
              className="form-control"
              type="number"
              min={1}
              max={10}
              value={item?.quantity || 1}
              onChange={(e) =>
                setProductupdateDetail({
                  cartItemId: item?._id,
                  quantity: e.target.value,
                })
              }
            />
          </div>
          <div>
            <AiFillDelete
              onClick={() => deleteACartProduct(item?._id)}
              className="text-danger"
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div className="cart-col-4">
          <h5 className="price">Rs. {item?.quantity * (item?.price || 0)}</h5>
        </div>
      </div>
    );
  })}

          </div>
          <div className="col-12 py-2 mt-4">
            <div className="d-flex justify-content-between align-items-baseline">
            <button
                    onClick={() => navigate("/product")}
                      className="button"
                      style={{
                        backgroundColor: "#212122",
                        color: "#fff",
                        padding: "14px 30px",
                        fontSize: "16px",
                        borderRadius: "8px",
                        border: "none",
                        cursor: "pointer",
                        width: "25%",
                        transition: "background-color 0.3s ease, transform 0.2s ease",
                      }}
                      onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")}
                      onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
                    >
                      Continue to Shopping
                    </button>
              {(totalAmount !== null || totalAmount !== 0) && (
                <div className="d-flex flex-column align-items-end">
                  <h4>
                    SubTotal: Rs.{" "}
                    {!userCartState?.length ? 0 : totalAmount ? totalAmount : 0}
                  </h4>
                  <p>Taxes and shipping calculated at checkout</p>
                  <button
                    onClick={() => navigate("/checkout")}
                      className="button"
                      style={{
                        backgroundColor: "#212122",
                        color: "#fff",
                        padding: "14px 30px",
                        fontSize: "16px",
                        borderRadius: "8px",
                        border: "none",
                        cursor: "pointer",
                        width: "200px",
                        transition: "background-color 0.3s ease, transform 0.2s ease",
                      }}
                      onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")}
                      onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
                    >
                      Checkout
                    </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Cart;
