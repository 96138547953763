import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import compare from "../images/compare.svg";
import wishlist from "../images/wishlist.svg";
import user from "../images/user.svg";
import cart from "../images/cart.svg";
import menu from "../images/menu.svg";
import { useDispatch, useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { getAProduct } from "../features/products/productSlilce";
import { getUserCart } from "../features/user/userSlice";

const Header = () => {
  const dispatch = useDispatch();
  const cartState = useSelector((state) => state?.auth?.cartProducts);
  const authState = useSelector((state) => state?.auth);
  const [total, setTotal] = useState(null);
  const [paginate, setPaginate] = useState(true);
  const productState = useSelector((state) => state?.product?.product);
  const navigate = useNavigate();

  const getTokenFromLocalStorage = localStorage.getItem("customer")
    ? JSON.parse(localStorage.getItem("customer"))
    : null;

  const config2 = {
    headers: {
      Authorization: `Bearer ${
        getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ""
      }`,
      Accept: "application/json",
    },
  };

  useEffect(() => {
    dispatch(getUserCart(config2));
  }, []);

  const [productOpt, setProductOpt] = useState([]);
  useEffect(() => {
    let sum = 0;
    for (let index = 0; index < cartState?.length; index++) {
      sum = sum + Number(cartState[index].quantity) * cartState[index].price;
      setTotal(sum);
    }
  }, [cartState]);

  useEffect(() => {
    let data = [];
    for (let index = 0; index < productState?.length; index++) {
      const element = productState[index];
      data.push({ id: index, prod: element?._id, name: element?.title });
    }
    setProductOpt(data);
  }, [productState]);

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };
  return (
    <>
      {/* <header className="header-top-strip py-3">
        <div className="container-xxl">
          <div className="row">
            <div className="col-6">
              <p className="text-white mb-0">Free Shipping Over Rs.100</p>
            </div>
            <div className="col-6">
              <p className="text-end text-white mb-0">
                Hotline:
                <a className="text-white" href="tel:+91 8264954234">
                  +91 8264954234
                </a>
              </p>
            </div>
          </div>
        </div>
      </header> */}
      <header className="header-upper py-3" style={{ backgroundColor: "#212122" }}>
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="col-2">
              <h2>
                <Link style={{ color: "#bfff00" }} to="/ ">
                  Areaby
                </Link>
              </h2>
            </div>
            <div className="col-5">
              <div className="input-group rounded">
                <Typeahead
                  id="pagination-example"
                  onPaginate={() => console.log("Results paginated")}
                  onChange={(selected) => {
                    navigate(`/product/${selected[0]?.prod}`);
                    dispatch(getAProduct(selected[0]?.prod));
                  }}
                  options={productOpt}
                  paginate={paginate}
                  labelKey={"name"}
                  placeholder="Search for Products here"
                />
                <span className="input-group-text p-3" id="basic-addon2" style={{ backgroundColor: "#bfff00" }}>
                  <BsSearch className="fs-6 " />
                </span>
              </div>
            </div>
            <div className="col-5">
              <div className="header-upper-links d-flex align-items-center gap-4 justify-content-end ">
                <div>
                  {/* <Link
                    to="/compare-product"
                    className="d-flex align-items-center gap-10 text-white"
                  >
                    <img src={compare} alt="compare" />
                    <p className="mb-0">
                      Compare <br /> Products
                    </p>
                  </Link> */}
                </div>
                <div>
                  <Link
                    to="/wishlist"
                    className="text-center"
                  >
                    <img src="https://img.icons8.com/?size=100&id=85038&format=png&color=FFFFFF" width={25} height={25} />
                    <p className="mb-0" style={{ color: "#bfff00" }} >
                        Wishlist
                    </p>
                  </Link>
                </div>
                <div>
                  <Link
                    to={authState?.user === null ? "/login" : "my-profile"}
                    className="text-center"
                  >
                    <img src="https://img.icons8.com/?size=100&id=ABBSjQJK83zf&format=png&color=FFFFFF" width={27} height={27} alt="login" />
                    {authState?.user === null ? (
                      <p className="mb-0 " style={{ color: "#bfff00" }}>
                        Login
                      </p>
                    ) : (
                      <p className="mb-0" style={{ color: "#bfff00" }}>
                        Welcome <span style={{ color: "white" }}>{authState?.user?.firstname}</span>
                      </p>
                    )}
                  </Link>
                </div>
                
                <div>
                  <Link
                    to="/cart"
                    className="d-flex align-items-center gap-10 text-white"
                  >
                    
                    <div style={{ position: "relative", display: "inline-block" }}>
                  <img
                    src="https://img.icons8.com/?size=100&id=Vfx42bq0G2lJ&format=png&color=FFFFFF"
                    className="superscript-text"
                    width={27}
                    height={27}
                    alt="cart"
                  />
                  <span className="rounded-circle badge text-dark w-75 superscript-text" style={{ backgroundColor: "#bfff00", color: "#000", position: "absolute", top: "-10px", right: "-10px", fontSize: "12px" }}>
                    {cartState?.length ? cartState?.length : 0}
                  </span>
                  <p className="mb-0 " style={{ color: "#bfff00" }}>
                        Cart
                      </p>
                  </div>
                    
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <header className="header-bottom py-3" style={{ backgroundColor: "#353839" }}>
        <div className="container-xxl">
          <div className="row">
            <div className="col-12">
              <div className="menu-bottom d-flex align-items-center gap-30">
                <div>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle bg-transparent border-0 gap-15 d-flex align-items-center"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={"https://img.icons8.com/?size=100&id=PyTwzzQVz0MQ&format=png&color=FFFFFF"} alt="" width={27} height={27}/>
                      <span className=" d-inline-block" style={{ textDecoration: "none", color: "#bfff00" }}>
                        Shop Categories
                      </span>
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {productState &&
                        productState.map((item, index) => {
                          return (
                            <li key={index}>
                              <Link className="dropdown-item text-white" to="">
                                {item?.category}
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className="menu-links">
                  <div className="d-flex align-items-center gap-15">
                    <NavLink style={{ textDecoration: "none", color: "#bfff00" }} to="/">Home</NavLink>
                    <NavLink style={{ textDecoration: "none", color: "#bfff00" }} to="/product">Our Store</NavLink>
                    <NavLink style={{ textDecoration: "none", color: "#bfff00" }} to="/my-orders">My Orders</NavLink>
                    {/* <NavLink style={{ textDecoration: "none", color: "#bfff00" }} to="/blogs">Blogs</NavLink> */}
                    <NavLink style={{ textDecoration: "none", color: "#bfff00" }} to="/contact">Contact</NavLink>
                    {authState?.user !== null ? (
                      <NavLink
                      style={{ textDecoration: "none", color: "#bfff00" }}
                        onClick={handleLogout}
                      >
                        Logout
                      </NavLink>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <style>
        {`@media (max-width: 425px) {
  /* Hide everything except the search icon */
  .header-upper .input-group {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .header-upper .header-upper-links {
    display: none;
  }

  .header-upper .input-group .input-group-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .header-upper .input-group .fs-6 {
    display: block;
  }

  /* Show only the necessary icons (Wishlist, Profile, Cart) */
  .header-upper .header-upper-links {
    display: flex;
    gap: 15px;
    justify-content: center;
  }

  .header-upper .header-upper-links > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header-upper .header-upper-links .text-center {
    display: flex;
    justify-content: center;
  }

  .header-bottom {
    display: none;
  }

  .header-bottom .menu-bottom {
    display: none;
  }

  .header-bottom .menu-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header-bottom .menu-links a {
    margin-bottom: 10px;
    color: #bfff00;
    text-decoration: none;
  }

  .header-bottom .menu-links a:hover {
    color: #fff;
  }

  /* Adjust header links layout for small screens */
  .header-bottom .menu-links .d-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
`}
      </style>
    </>
  );
};

export default Header;
