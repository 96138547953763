import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import Container from "../components/Container";
import CustomInput from "../components/CustomInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../features/user/userSlice";

let loginSchema = yup.object({
  email: yup
    .string()
    .required("Email is Required")
    .email("Email Should be valid"),
  password: yup.string().required("Password is Required"),
});

const Login = () => {
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      dispatch(loginUser(values));
    },
  });

  useEffect(() => {
    if (authState.user !== null && authState.isError === false) {
      window.location.href = "/";
    }
  }, [authState]);

  return (
    <>
      <Meta title={"Login"} />
      <BreadCrumb title="Login" />

      <Container class1="login-wrapper py-5 home-wrapper-2">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div
              className="auth-card"
              style={{
                background: "light",
                padding: "40px",
                borderRadius: "12px",
                boxShadow: "0 15px 40px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h3
                className="text-center mb-4"
                style={{
                  color: "#212122", 
                  fontWeight: "600", 
                  fontSize: "24px"
                }}
              >
                Welcome Back
              </h3>
              <form
                action=""
                onSubmit={formik.handleSubmit}
                className="d-flex flex-column gap-2"
              >
                <CustomInput
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange("email")}
                  onBlur={formik.handleBlur("email")}
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "2px solid #212122",
                    color: "#4c00b0",
                    borderRadius: "8px",
                    padding: "14px 20px",
                    fontSize: "16px",
                    transition: "border-color 0.3s ease",
                    marginBottom: "12px",
                  }}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="error" style={{ color: "#FF4081" }}>
                    {formik.errors.email}
                  </div>
                )}

                <CustomInput
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange("password")}
                  onBlur={formik.handleBlur("password")}
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "2px solid #4c00b0",
                    color: "#4c00b0",
                    borderRadius: "8px",
                    padding: "14px 20px",
                    fontSize: "16px",
                    transition: "border-color 0.3s ease",
                    marginBottom: "20px",
                  }}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="error" style={{ color: "#FF4081" }}>
                    {formik.errors.password}
                  </div>
                )}

                <div className="d-flex justify-content-between align-items-center mt-3">
                  <Link
                    to="/forgot-password"
                    style={{
                      color: "#212122",
                      textDecoration: "none",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Forgot Password?
                  </Link>
                </div>

                <div className="mt-4 d-flex justify-content-center gap-20">
                  <button
                    type="submit"
                    className="button"
                    style={{
                      backgroundColor: "#212122",
                      color: "#fff",
                      padding: "14px 30px",
                      fontSize: "16px",
                      borderRadius: "8px",
                      border: "none",
                      cursor: "pointer",
                      width: "100%",
                      transition: "background-color 0.3s ease, transform 0.2s ease",
                    }}
                    onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")}
                    onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
                  >
                    Login
                  </button>
                </div>

                <div className="mt-3 text-center">
                  <span
                    style={{
                      color: "#212122",
                      fontSize: "14px",
                    }}
                  >
                    Don't have an account?{" "}
                    <Link
                      to="/signup"
                      style={{
                        color: "#FF4081",
                        fontSize: "14px",
                        fontWeight: "500",
                        textDecoration: "none",
                      }}
                    >
                      Sign Up
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Login;
