import axios from "axios";
import { base_url } from "../../utils/axiosConfig";

// Fetch all blogs
const getBlogs = async () => {
  try {
    const response = await axios.get(`${base_url}blog`);
    return response.data;
  } catch (error) {
    console.error("Error fetching blogs:", error.response?.data || error.message);
    return null;
  }
};

// Fetch a single blog by ID
const getBlog = async (id) => {
  try {
    const response = await axios.get(`${base_url}blog/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching blog with ID ${id}:`, error.response?.data || error.message);
    return null;
  }
};

export const blogService = {
  getBlogs,
  getBlog,
};
