import React, { useState } from "react";

const Color = ({ colorData, setColor }) => {
  const [selectedColor, setSelectedColor] = useState(null);

  const handleColorSelect = (colorId) => {
    setSelectedColor(colorId);
    setColor(colorId);
  };

  return (
    <ul className="colors ps-0" style={{ display: "flex", gap: "10px" }}>
      {colorData?.map((item, index) => (
        <li
          key={index}
          onClick={() => handleColorSelect(item?._id)}
          style={{
            backgroundColor: item?.title,
            outline: selectedColor === item?._id ? "2px solid black" : "none",
            outlineOffset: "3px", // Moves the outline outward
            cursor: "pointer",
            width: "30px",
            height: "30px",
            borderRadius: "50%", // Circular shape
            display: "inline-block",
            border: "1px solid black", // Maintains a subtle inner border
          }}
        ></li>
      ))}
    </ul>
  );
};

export default Color;
