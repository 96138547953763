import axios from "axios";
import { base_url } from "../../utils/baseUrl";

// Function to get auth headers dynamically
const getAuthHeaders = () => {
  try {
    // Check if token exists under 'customer'
    const customerData = localStorage.getItem("customer");
    let token = null;

    if (customerData) {
      const parsedCustomer = JSON.parse(customerData);
      token = parsedCustomer.token;
    }

    // Fallback to 'token' key if 'customer' does not contain token
    if (!token) {
      token = localStorage.getItem("token");
    }

    if (!token) {
      console.warn("No authentication token found.");
      return { headers: { Accept: "application/json" } };
    }

    return {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
  } catch (error) {
    console.error("Error retrieving auth headers:", error);
    return { headers: { Accept: "application/json" } };
  }
};



// Fetch products with optional filters
const getProducts = async (filters) => {
  try {
    const queryParams = [
      filters?.brand ? `brand=${filters.brand}` : "",
      filters?.tag ? `tags=${filters.tag}` : "",
      filters?.category ? `category=${filters.category}` : "",
      filters?.minPrice ? `price[gte]=${filters.minPrice}` : "",
      filters?.maxPrice ? `price[lte]=${filters.maxPrice}` : "",
      filters?.sort ? `sort=${filters.sort}` : "",
    ]
      .filter(Boolean)
      .join("&");

    const response = await axios.get(
      `${base_url}product${queryParams ? "?" + queryParams : ""}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    return null;
  }
};

// Fetch a single product by ID
const getSingleProduct = async (id) => {
  if (!id) {
    console.error("Product ID is undefined. Cannot fetch product.");
    return null;
  }

  try {
    const response = await axios.get(`${base_url}product/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching product:", error.response?.data || error);
    return null;
  }
};


// Add a product to the wishlist
const addToWishlist = async (prodId) => {
  try {
    const authHeaders = getAuthHeaders();
    if (!authHeaders.headers.Authorization) {
      console.error("User is not authenticated. Cannot add to wishlist.");
      return null;
    }

    const response = await axios.put(
      "https://areaby-backend.onrender.com/api/product/wishlist",
      { prodId },
      authHeaders
    );
    console.log("Wishlist updated successfully", response.data);
    return response.data;
  } catch (error) {
    console.error("Error adding to wishlist:", error.response?.data || error);
    return null;
  }
};




// Rate a product
const rateProduct = async (data) => {
  try {
    const response = await axios.put(
      `${base_url}product/rating`,
      data,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error rating product:", error);
    return null;
  }
};

// Exporting product service
export const productSevice = {
  getProducts,
  getSingleProduct,
  addToWishlist,
  rateProduct,
};
